@font-face {
    font-family: 'TTBerlinerinsScript';
    src: url('/assets/fonts/TTBerlinerinsScript/TTBerlinerinsScript.eot');
    src: local('☞TTBerlinerinsScript'), local('TTBerlinerinsScript'),
        url('/assets/fonts/TTBerlinerinsScript/TTBerlinerinsScript.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/TTBerlinerinsScript/TTBerlinerinsScript.woff2') format('woff2'),
        url('/assets/fonts/TTBerlinerinsScript/TTBerlinerinsScript.woff') format('woff'),
        url('/assets/fonts/TTBerlinerinsScript/TTBerlinerinsScript.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}