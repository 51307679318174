@font-face {
    font-family: 'Gerbera';
    src: url('/assets/fonts/Gerbera/Gerbera.eot');
    src: local('☞Gerbera'), local('Gerbera'),
        url('/assets/fonts/Gerbera/Gerbera.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Gerbera/Gerbera.woff2') format('woff2'),
        url('/assets/fonts/Gerbera/Gerbera.woff') format('woff'),
        url('/assets/fonts/Gerbera/Gerbera.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gerbera';
    src: url('/assets/fonts/Gerbera/Gerbera-Light.eot');
    src: local('☞Gerbera Light'), local('Gerbera-Light'),
        url('/assets/fonts/Gerbera/Gerbera-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Gerbera/Gerbera-Light.woff2') format('woff2'),
        url('/assets/fonts/Gerbera/Gerbera-Light.woff') format('woff'),
        url('/assets/fonts/Gerbera/Gerbera-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gerbera';
    src: url('/assets/fonts/Gerbera/Gerbera-Black.eot');
    src: local('☞Gerbera Black'), local('Gerbera-Black'),
        url('/assets/fonts/Gerbera/Gerbera-Black.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Gerbera/Gerbera-Black.woff2') format('woff2'),
        url('/assets/fonts/Gerbera/Gerbera-Black.woff') format('woff'),
        url('/assets/fonts/Gerbera/Gerbera-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gerbera';
    src: url('/assets/fonts/Gerbera/Gerbera-Bold.eot');
    src: local('☞Gerbera Bold'), local('Gerbera-Bold'),
        url('/assets/fonts/Gerbera/Gerbera-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Gerbera/Gerbera-Bold.woff2') format('woff2'),
        url('/assets/fonts/Gerbera/Gerbera-Bold.woff') format('woff'),
        url('/assets/fonts/Gerbera/Gerbera-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gerbera';
    src: url('/assets/fonts/Gerbera/Gerbera-Medium.eot');
    src: local('☞Gerbera Medium'), local('Gerbera-Medium'),
        url('/assets/fonts/Gerbera/Gerbera-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/Gerbera/Gerbera-Medium.woff2') format('woff2'),
        url('/assets/fonts/Gerbera/Gerbera-Medium.woff') format('woff'),
        url('/assets/fonts/Gerbera/Gerbera-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

